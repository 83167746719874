(function ($) {
  $("#menu-location-select").on("change", function () {
    var location = this.value;
    //Handle Menu Items Visibility
    var locationClass = ".available-at-" + location;
    $(".menu-item:not(.all-locations)").css("display", "none");
    $(locationClass).css("display", "block");
    //Handle Pricing Changes
    var priceClass = "." + location + "-price";
    $(".location-price").css("display", "none");
    $(".menu-item-price-default").css("display", "none");

    $(".menu-item-price").each(function () {
      if ($(this).find(priceClass).length) {
        $(this).find(priceClass).css("display", "inline");
        return true;
      } else {
        $(this).find(".menu-item-price-default").css("display", "inline");
      }
    });

    $(".menu-items").each(function () {
      if ($(this).height() < 200) {
        $(this).css("display", "none");
      } else {
        $(this).css("display", "block");
      }
    });
  });

  if ($(window).width() < 640) {
    console.log("fired");
    $(document).ready(function () {
      $("li.accordion-item.is-active .accordion-content").css(
        "display",
        "none"
      );
      $("li.accordion-item.is-active").removeClass("is-active");
    });
  }

  //Collapse Accordions by default on mobile
})(jQuery);
